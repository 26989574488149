// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-home-js": () => import("/app/src/templates/home.js" /* webpackChunkName: "component---src-templates-home-js" */),
  "component---src-templates-model-detail-js": () => import("/app/src/templates/model_detail.js" /* webpackChunkName: "component---src-templates-model-detail-js" */),
  "component---src-templates-blog-detail-js": () => import("/app/src/templates/blog_detail.js" /* webpackChunkName: "component---src-templates-blog-detail-js" */),
  "component---src-templates-generic-page-js": () => import("/app/src/templates/generic_page.js" /* webpackChunkName: "component---src-templates-generic-page-js" */),
  "component---src-templates-promotions-js": () => import("/app/src/templates/promotions.js" /* webpackChunkName: "component---src-templates-promotions-js" */),
  "component---src-templates-newsletter-js": () => import("/app/src/templates/newsletter.js" /* webpackChunkName: "component---src-templates-newsletter-js" */),
  "component---src-templates-maintenance-js": () => import("/app/src/templates/maintenance.js" /* webpackChunkName: "component---src-templates-maintenance-js" */),
  "component---src-templates-contact-us-js": () => import("/app/src/templates/contact_us.js" /* webpackChunkName: "component---src-templates-contact-us-js" */),
  "component---src-templates-about-js": () => import("/app/src/templates/about.js" /* webpackChunkName: "component---src-templates-about-js" */),
  "component---src-templates-shop-online-js": () => import("/app/src/templates/shopOnline.js" /* webpackChunkName: "component---src-templates-shop-online-js" */),
  "component---src-templates-blog-js": () => import("/app/src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-comparator-js": () => import("/app/src/templates/comparator.js" /* webpackChunkName: "component---src-templates-comparator-js" */),
  "component---src-templates-dealers-js": () => import("/app/src/templates/dealers.js" /* webpackChunkName: "component---src-templates-dealers-js" */),
  "component---src-templates-help-js": () => import("/app/src/templates/help.js" /* webpackChunkName: "component---src-templates-help-js" */),
  "component---src-pages-404-js": () => import("/app/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-enviado-js": () => import("/app/src/pages/enviado.js" /* webpackChunkName: "component---src-pages-enviado-js" */)
}

